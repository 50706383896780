import request from "@/utils/request";

// 回复列表
export function replyList(params) {
  return request({
      url: '/admin/french/replyList',
      method: 'get',
      params
  })
}

// 删除回复
export function delReply(data) {
  return request({
      url: '/admin/french/delReply',
      method: 'delete',
      data
  })
}



      

      
      

