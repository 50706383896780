<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
         <!-- <el-form-item label="排序">
          <el-select
            v-model="queryForm.sort"
            placeholder="请选择"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in sortList"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="手机">
          <el-input
            placeholder="请输入手机号码"
            v-model="queryForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="帖子查询">
          <el-input
            placeholder="请输入帖子id"
            v-model="queryForm.topic_id"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="queryForm.check"
            placeholder="请选择"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in isDisable"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toDisable"
            >批量禁用</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAble"
            >批量启用</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="commentList"
        stripe
        class="table"
        ref="multipleSelection"
        header-cell-class-name="table-header"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column label="ID" prop="id" align="center"></el-table-column>
        <el-table-column label="评论内容" prop="msg" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="发帖人昵称" prop="user_info.nickname" align="center"></el-table-column>
        <el-table-column label="发帖人手机" prop="user_info.phone" align="center"></el-table-column>
        <el-table-column label="所属帖子ID" prop="topic_id" align="center"></el-table-column>
        <el-table-column label="评论时间" align="center">
          <template slot-scope="scope">
             {{scope.row.create_time}}
          </template>
        </el-table-column>
        <el-table-column label="点赞数" prop="like" align="center"></el-table-column>
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="getCommentDetail(scope.row)"
              size="small"
            ></el-button>
            <!-- <el-button
              type="danger"
              icon="el-icon-minus"
              circle
              @click="removeTask(scope.row.id)"
              size="small"
            ></el-button> -->
            <el-tag type="warning" v-if="queryForm.check == 1" @click="removeTask(scope.row.id)" style="margin-left:10px">
              禁用
            </el-tag>
            <el-tag type="warning" v-else-if="queryForm.check == -1" @click="removeTask(scope.row.id)" style="margin-left:10px">
              启用
            </el-tag>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 帖子详情 -->
      <el-dialog :title="addtitle" :visible.sync="commentDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
        <el-form :model="commentDetail"  ref="addForm">
          <!-- <el-form-item el-form-item label="评论状态：" label-width="100px">
            {{commentDetail.}}
          </el-form-item> -->
          <el-form-item el-form-item label="所属帖子ID:" label-width="100px">
            {{commentDetail.topic_id}}
          </el-form-item>
          <el-form-item el-form-item label="评论者昵称：" label-width="100px">
            {{commentDetail.nickname}}
          </el-form-item>
          <el-form-item el-form-item label="评论者手机：" label-width="100px">
            {{commentDetail.phone}}
          </el-form-item>
          <el-form-item el-form-item label="评论内容：" label-width="100px">
            {{commentDetail.msg}}
          </el-form-item>
          <el-form-item el-form-item label="评论时间：" label-width="100px">
            {{commentDetail.create_time}}
          </el-form-item>
          <el-form-item el-form-item label="点赞数：" label-width="100px">
            {{commentDetail.like}}
          </el-form-item>
        </el-form>
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");
import {
  replyList,
  delReply
} from "@/api/French/comment_management.js";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        // sort: 2,
        check: 1,
        phone: "",
        topic_id: "",
        title_type: "name",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      commentList: [],
      commentDetail: {},
      // sortList: [{key:1,value:"正序"},{key:2,value:"倒序"}],
      isDisable: [{key:-1,value:"禁用"},{key:1,value:"启用"}],
      total: 0,
      commentDialog: false,
      times: null,
      addForm: {
        grade: "",
        line: "",
        part: "",
        title: "",
        content: "",
      },
      addtitle: "",
      isAdd: "add",
      lineData: [],
      isCascader: true,
      multipleSelection: []
    };
  },

  created() {
    this.getCommentList({check:1});
  },
  methods: {
    //获取多选数组
    handleSelectionChange(val) {
      let checkArr = []
      val.forEach((item)=>{
        checkArr.push(item.id)
      })
      this.multipleSelection = checkArr;
    },
    //获取帖子列表
    getCommentList(item) {
      replyList(item).then((res) => {
        console.log(res)
        // if(item.sort == 1){
        //   this.topicList = res.data.data.reverse()
        // }else{
        //   this.topicList = res.data.data
        // }
        this.topicList = res.data.data
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.commentList = res.data.data;
      });
    },

    //设置精华
    setTopicEssence(item){
      console.log(item)
      let data = {}
      if(item.is_essence == 0){
        data = {id:[item.id],status:1}
      }else if(item.is_essence == 1){
        data = {id:[item.id],status:0}
      }
      topicEssence(data).then((res)=>{
        console.log(res)
        this.getTopicList(this.queryForm);
      })
    },

    // 禁用帖子
    async removeTask(id) {
      let data = {}
      if(this.queryForm.check == 1){
        data.id = [id],
        data.status = -1
        const result = await this.$confirm("是否要禁用该帖子?", "禁用提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        if (result !== "confirm") return this.$message.info("已经取消禁用");
        delReply(data).then((res) => {
          if (res.code !== 1) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("禁用帖子成功");
          this.getCommentList(this.queryForm);
        });
      }else{
        data.id = [id],
        data.status = 1
        delReply(data).then((res) => {
          if (res.code !== 1) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("启用帖子成功");
          this.getCommentList(this.queryForm);
        });
      }
    },

    //批量禁用
    async toDisable(){
      const result = await this.$confirm("是否要禁用这些帖子?", "禁用提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消禁用");
      delReply({id:this.multipleSelection,status:-1}).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("禁用帖子成功");
        this.getCommentList(this.queryForm);
      });
    },

    //批量启用
    async toAble(){
      const result = await this.$confirm("是否要启用这些帖子?", "启用提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消启用");
      delReply({id:this.multipleSelection,status:1}).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("启用帖子成功");
        this.getCommentList(this.queryForm);
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "帖子详情";
      this.lineData = [];
      this.addDialog = true;
      this.isCascader = true;
    },

    // 点击编辑图标
    getCommentDetail(item) {
      this.addtitle = "回复详情";
      this.commentDialog = true;
      // console.log(item)
      item.nickname = item.user_info.nickname;
      item.phone = item.user_info.phone;
      this.commentDetail = item;
    },

    // 弹窗关闭事件
    closed() {
      this.commentDetail = {};
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        // this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        // this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
        this.queryForm.start_time = this.times[0].getFullYear()+ '-' +(this.times[0].getMonth() + 1) + '-' + this.times[0].getDate() 
	      + ' ' + this.checkTime(this.times[0].getHours()) + ':' + this.checkTime(this.times[0].getMinutes()) + ':' + 
        this.checkTime(this.times[0].getSeconds());
        this.queryForm.end_time = this.times[1].getFullYear()+ '-' +(this.times[1].getMonth() + 1) + '-' + this.times[1].getDate() 
	      + ' ' + this.checkTime(this.times[1].getHours()) + ':' + this.checkTime(this.times[1].getMinutes()) + ':' + 
        this.checkTime(this.times[1].getSeconds());
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      console.log(this.queryForm)
      this.getCommentList(this.queryForm);
    },
    //日期格式化
    checkTime(i) {//时间
      if (i < 10) {
        i = "0" + i
      }
      return i;
    },
    handleSizeChange(size) {
      this.queryForm.size = size;
      this.getContentList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getCommentList(this.queryForm);
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getCommentList(this.queryForm);
    },
  },
};
</script>

<style scoped>
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
</style>
